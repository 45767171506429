<template>
    <div v-click-outside="resetEditActive">
        <span class="vbt-table-td" @dblclick="onDoubleClick" v-show="!editActive || !inlineEdit">{{formatValue(label)|striphtml}}</span>
        <vs-input
            v-if="!isSelectField && inlineEdit"
            v-show="editActive && inlineEdit"
            type="text"
            class="vbt-inline-edit-input"
            :value="label"
            v-model="colValue"
            ref="inlinEditInput"
            @keyup.enter="onKeyEnter"
            autofocus
        ></vs-input>
        <v-select
            v-show="editActive && inlineEdit"
            class="vbt-inline-edit-input"
            v-if="isSelectField && inlineEdit"
            v-model="selectValue"
            :options="selectOptions"
            :style="{width: 100+'%'}"
            :searchable="false"
            :clearable="false"
            @input="$emit('inlineEditChange', { id: id, column: column, value: selectValue.name})"
        >
        </v-select>
    </div>
</template>

<script>
    import vSelect from 'vue-select'

    export default {
        components: {
            'v-select': vSelect,
        },
        name: "RowColumn",
        props: ['inlineEdit', 'column', 'label', 'id'],
        data() {
            return {
                editActive: false,
                colValue: null,
                selectValue: null
            }
        },
        created() {
            this.colValue = this.label;
            this.setDefaultSelectValue()
        },
        computed: {
            isSelectField() {
                if(!this.column.filter) {
                    return true;
                }

                if(this.column.filter.type !== 'select') {
                    return false;
                }

                return true;
            },
            selectOptions() {
                if(!this.column.filter) {
                    return [];
                }
                if(!this.column.filter.options) {
                    return [];
                }
                return this.column.filter.options;
            }
        },
        methods: {
            formatValue(value) {
                if(!this.column.formatter) {
                    return value;
                }

                if (typeof this.column.formatter !== "function") {
                    console.error('formatter has to be a function');
                    return value;
                }

                return this.column.formatter(value);
            },
            onDoubleClick() {
                this.editActive = true
            },
            resetEditActive() {
                this.editActive = false
            },
            onKeyEnter(event) {

                this.$emit('inlineEditChange', { id: this.id, column: this.column, value: this.colValue})

                this.resetEditActive();
            },
            setDefaultSelectValue() {
                if(!this.isSelectField) {
                    return;
                }

                if(!this.column.filter) {
                    return [];
                }
                if(!this.column.filter.options) {
                    return [];
                }

                let options = this.column.filter.options;

                for(let i = 0; i < options.length; i++) {
                    if(options[i].value === this.colValue) {
                        this.selectValue = options[i];
                    }
                }
            }
        },
        directives: {
            clickOutside: {
                bind: function (el, binding, vnode) {
                    el.clickOutsideEvent = function (event) {
                        if (!(el === event.target || el.contains(event.target))) {
                            vnode.context[binding.expression](event);
                        }
                    };
                    document.body.addEventListener('click', el.clickOutsideEvent)
                },
                unbind: function (el) {
                    document.body.removeEventListener('click', el.clickOutsideEvent)
                },
            }
        },
        watch: {
            label(newValue, oldValue) {
                this.colValue = newValue;
                this.setDefaultSelectValue()
            }
        }
    }
</script>

<style scoped>

</style>
