<template>
  <div class="mg-pagination flex text-center border border-gray-300 text-gray-500">
    <template v-for="button in preButtons">
      <button type="button" :class="button.classes" @click="button.callback" :disabled="button.disabled">
        <feather-icon v-if="button.icon" :icon="button.icon" svgClasses="w-4 h-4" />
        <span v-else> {{ button.label }}</span>
      </button>
    </template>
    <template v-for="button in pageButtons">
      <button type="button" :class="button.classes" @click="button.callback" :disabled="button.disabled">
        {{ button.label }}
      </button>
    </template>
    <template v-for="button in postButtons">
      <button type="button" :class="button.classes" @click="button.callback" :disabled="button.disabled">
        <feather-icon v-if="button.icon" :icon="button.icon" svgClasses="w-4 h-4" />
        <span v-else> {{ button.label }}</span>
      </button>
    </template>
  </div>
</template>

<script>
import { range } from 'lodash';

export default {
  name: "MgPagination",

  props: {
    value: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    perPage: {
      type: Number,
      default: 20,
      validator: (value) => value > 0,
    },
    totalItems: {
      type: Number,
      default: 0,
      validator: (value) => value >= 0,
    },
    limit: {
      type: Number,
      default: 5,
      validator: (value) => value >= 0,
    },
    hideEllipsis: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentPage: null,
      preButtons: [],
      postButtons: [],
      pageButtons: []
    };
  },

  computed: {
    totalPages() {
      if (this.perPage <= 0) {
        return 0;
      }

      return Math.ceil(this.totalItems / this.perPage);
    },

    prevIsDisabled() {
      return this.currentPage <= 1;
    },

    nextIsDisabled() {
      return this.currentPage >= this.totalPages;
    },

    pageRange() {
      const from1 = Number(this.currentPage) - Math.round(this.limit / 2) + 1;
      const from2 = (this.totalPages) + 1 - (this.limit);
      const from = Math.max(Math.min(from1, from2), 1);

      const to = Math.min(from + this.limit - 1, this.totalPages);

      return range(from, to + 1).map((page) => {
        if (!this.hideEllipsis && page === from && from > 1) {
          return 'less';
        }

        if (!this.hideEllipsis && page === to && to < this.totalPages) {
          return 'more';
        }

        return page;
      });
    },
    normalClasses() {
      return ['h-10 px-4 text-gray-800 hover:text-primary cursor-pointer overflow-hidden flex items-center'];
    },
    disabledClasses() {
      return ['h-10 px-4 flex items-center text-gray-400'];
    },
    activeClasses() {
      return ['h-10 px-4 flex items-center bg-primary text-white'];
    },
    ellipsisClasses() {
      return ['h-10 px-4 flex items-center text-gray-600'];
    }
  },

  mounted() {
    this.currentPage = this.value;
    this.createComponent();
  },

  watch: {
    value(value) {
      this.currentPage = value;
      this.createComponent();
    },
    perPage() {
      this.createComponent();
    },
    totalItems() {
      this.createComponent();
    }
  },

  methods: {
    createComponent() {
      this.preButtons = [];
      this.postButtons = [];
      this.pageButtons = [];

      this.preButtons.push({
        label: 'First',
        classes: this.getClasses(false, this.currentPage <= 1),
        disabled: this.currentPage <= 1,
        callback: this.goToFirst,
        icon: 'ChevronsLeftIcon'
      });

      this.preButtons.push({
        label: 'Previous',
        classes: this.getClasses(false, this.prevIsDisabled),
        disabled: this.prevIsDisabled,
        callback: this.goToPrevious,
        icon: 'ChevronLeftIcon'
      });

      this.postButtons.push({
        label: 'Next',
        classes: this.getClasses(false, this.nextIsDisabled),
        disabled: this.nextIsDisabled,
        callback: this.goToNext,
        icon: 'ChevronRightIcon'
      });

      this.postButtons.push({
        label: 'Last',
        classes: this.getClasses(false, this.currentPage >= this.totalPages),
        disabled: this.currentPage >= this.totalPages,
        callback: this.gotToLast,
        icon: 'ChevronsRightIcon'
      });

      this.pageRange.forEach((page) => {
        if (page === 'less' || page === 'more') {
          this.pageButtons.push({
            label: '...',
            classes: this.ellipsisClasses,
            disabled: true,
            callback: () => {
            }
          });
        } else {
          this.pageButtons.push({
            label: page,
            classes: this.getClasses(this.currentPage == page),
            disabled: this.currentPage === page,
            callback: () => {
              return this.goToPage(page)
            }
          });
        }
      });

    },

    getClasses(active = false, disabled = false) {
      if(disabled) {
        return this.disabledClasses;
      }

      if(active) {
        return this.activeClasses;
      }

      return this.normalClasses;
    },

    goToPage(page = 1) {
      this.currentPage = page;
      this.$emit('input', page);
      this.$emit('update', page)
    },

    goToPrevious() {
      const page = this.currentPage - 1;

      if (page < 1 || page > this.totalPages) {
        return;
      }

      this.goToPage(page)
    },

    goToNext() {
      const page = this.currentPage + 1;

      if (page < 1 || page > this.totalPages) {
        return;
      }

      this.goToPage(page)
    },

    goToFirst() {
      this.goToPage(1);
    },

    gotToLast() {
      this.goToPage(this.totalPages);
    },
  }

}
</script>