<template>
    <div class="table-select-group">
        <v-select
                v-if="isSingleMode"
                v-model="selected_index"
                class="table-select single-select"
                :placeholder="placeholder"
                :options="options"
                autowidth
                :style="{width: column.filter.width, minWidth: column.filter.minWidth}"
                :searchable="true"
        >
        </v-select>

        <v-select
            clearable
            v-if="!isSingleMode"
            v-model="selected_option_indexes"
            class="table-select"
            :placeholder="placeholder"
            :options="options"
            autowidth
            multiple
            :style="{width: column.filter.width, minWidth: column.filter.minWidth}"
        >
        </v-select>
    </div>
</template>

<script>
import findIndex from "lodash/findIndex";
import range from "lodash/range";
import filter from "lodash/filter";
import includes from "lodash/includes";
import has from "lodash/has";
import cloneDeep from "lodash/cloneDeep";

import vSelect from 'vue-select'

import {
    EventBus
} from '../../event-bus.js';
import MultiSelectAllItem from "./MultiSelectAllItem";

export default {
    name: "MultiSelect",
    props: {
        column: {
            type: Object,
            default: function () {
                return {

                };
            }
        },
        options: {
            type: Array,
            default: function () {
                return []
            }
        },
        placeholder: {
            type: String,
            default: "Auswählen"
        },
        value: {
            default: null
        },
    },
    data: function () {
        return {
            selected_option_indexes: [],
            selected_index: null,
            canEmit: true
        };
    },
    created() {
        if(this.value !== null && this.isSingleMode) {
            this.selected_index = this.value;
        }
        if(this.value !== null && !this.isSingleMode) {
            this.selected_option_indexes = this.value;
        }
    },
    mounted() {
        if(this.value !== null && this.isSingleMode) {
            this.selected_index = this.value;
        }
        if(this.value !== null && !this.isSingleMode) {
            this.selected_option_indexes = this.value;
        }

        EventBus.$on('reset-query', () => {
            this.selected_option_indexes = [];
            this.selected_index = null;
        });

        let lastIndex = this.optionsCount - 1;

        if (has(this.column,'filter.init.value')) {
            if (this.isSingleMode) {
                if (Array.isArray(this.column.filter.init.value)) {
                    this.addOption(this.column.filter.init.value[0])
                } else {
                    this.addOption(this.column.filter.init.value)
                }
            } else {
                if (Array.isArray(this.column.filter.init.value)) {
                    this.column.filter.init.value.forEach(value => {
                        if (!value) return;
                        this.addOption(value)
                    });
                } else {
                    console.log("Initial value for 'multi' mode should be an array");
                }
            }
        }

        this.$nextTick(() => { this.canEmit = true });
    },
    methods: {
        changeOptions(index) {
            this.resetSelectedOptions();
            this.selected_option_indexes.push(index);
        },
        addOption(value) {

            const index = this.options.map(option => {
                return option.value
            }, []).indexOf(parseInt(value));

            if(index === -1) {
                return;
            }

            if (this.isSingleMode) {
                this.resetSelectedOptions();
                this.selected_index = this.options[index];
            } else {
                let res = findIndex(this.selected_option_indexes, function (option_index) {
                    return option_index == index;
                });

                if (res == -1) {
                    this.selected_option_indexes.push(this.options[index]);
                }
            }
        },
        selectAllOptions() {
            this.resetSelectedOptions();
            this.selected_option_indexes = this.options;
        },
        removeOption(index) {
            if (this.isSingleMode) {
                this.resetSelectedOptions();
            } else {
                let res = findIndex(this.selected_option_indexes, function (option_index) {
                    return option_index == index;
                });
                if (res > -1) {
                    this.selected_option_indexes.splice(res, 1);
                }
            }
        },

        resetSingleSelect() {
            this.selected_index = null;
            this.selected_option_indexes = [];
        },

        resetSelectedOptions() {
            this.selected_option_indexes = [];
        },
        emitSelectedOptionIndexes(newVal) {

            if (!this.canEmit) return;

            let filtered_options = newVal;

            let payload = {};
            payload.column = cloneDeep(this.column);
            payload.selected_options = [];

            filtered_options.forEach(option => {
                payload.selected_options.push(option.value);
            });

            this.$emit('update-multi-select-filter', payload);
        },
        watchSelectedIndex(newVal, oldVal) {
            if(!newVal) {
                this.resetSelectedOptions();
                return;
            }

            this.resetSelectedOptions();
            this.selected_option_indexes.push(newVal);

         console.log("testss")
        }
    },
    components: {
        'v-select': vSelect,
    },
    computed: {
        optionsCount() {
            return this.options.length;
        },
        title() {
            let title = (this.column.filter.placeholder) ? (this.column.filter.placeholder) : "Select options";

            if (this.selected_option_indexes.length === 0) {
                return title;
            }

            if (this.selected_option_indexes.length > 0 && this.selected_option_indexes.length <= 1) {
                return this.options[this.selected_option_indexes[0]].name;
                // let filtered_options = filter(this.options, (option, index) => {
                //     return includes(this.selected_option_indexes, index)
                // });
                // let names = map(filtered_options, (option) => {
                //     return option.name
                // });
                // return join(names, ",  ");
            } else {
                return this.column.filter.selectedText.replace('{number}', this.selected_option_indexes.length);
            }

        },

        mode() {
            let mode = "single";
            if (has(this.column.filter, "mode") && this.column.filter.mode == "multi") {
                mode = "multi";
            }
            return mode;
        },

        isSingleMode() {
            return (this.mode == "single");
        },

        isAllOptionsSelected() {
            return this.options.length === this.selected_option_indexes.length;
        },

        showSelectAllCheckbox() {
            if (!has(this.column.filter,"select_all_checkbox")) {
                return true;
            } else {
                return this.column.filter.select_all_checkbox.visibility;
            }
        },

        selectAllCheckboxText() {
            if (!has(this.column.filter,"select_all_checkbox")) {
                return "Select All";
            } else {
                return (has(this.column.filter.select_all_checkbox,"text")) ? this.column.filter.select_all_checkbox.text : "Select All"
            }
        },
    },
    watch: {
        selected_option_indexes: {
            handler: 'emitSelectedOptionIndexes'
        },
        selected_index: {
            handler: 'watchSelectedIndex'
        },
    },
};
</script>

<style lang="scss">
.scrollable-menu {
    height: auto;
    max-height: 200px;
    overflow-x: hidden;
}
.vs--multiple .vs__clear {
    display: inline!important;
}
</style>
