import Router from '../../router';
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";

const RouteHelper = {
    removeQueryParam(name) {
        let query = Object.assign({}, Router.currentRoute.query);
        delete query[name];
        return Router.replace({ query });
    },
    replaceQueryParam(name, data) {
        let query = Object.assign({}, Router.currentRoute.query);
        query[name] = data;
        return Router.replace({ query });
    },
    getQueryParam(name) {
        return Router.currentRoute.query[name];
    },
    replaceQueryParams(query) {
        if(Object.keys(query).length < 1 && Object.keys(Router.currentRoute.query).length < 1) {
            return;
        }

        if(isEqual(query, Router.currentRoute.query)) {
            return;
        }

        return Router.replace({ query: query });
    },
    getAllQueryParams() {
        return Router.currentRoute.query;
    }
};

export default RouteHelper;
