import RouteHelper from '../../../../mixins/helper/route.helper';
import findIndex from "lodash/findIndex";

const RouteHandler = {
    setRouteQuery(payload) {
        let query = {};

        if (payload.sort && payload.sort.length) {
            query.o = [];

            payload.sort.forEach((item, index) => {
                query.o.push({
                    n: item.name,
                    o: item.order.toString()
                })
            });
        }

        if (payload.filters && payload.filters.length) {
            query.f = [];

            payload.filters.forEach((item, index) => {
                if (item.type === 'select') {
                    query.f.push({
                        name: item.name,
                        value: item.selected_options
                    });
                } else if (item.type === 'date') {
                    query.f.push({
                        name: item.name,
                        value: {
                            start: item.date[0],
                            end: item.date[1]
                        }
                    });
                } else {
                    query.f.push({
                        name: item.name,
                        value: item.text.toString()
                    });
                }
            });
        }

        if (payload.page !== null) {
            query.p = payload.page.toString();
        }

        if (payload.per_page !== null) {
            query.o = payload.per_page.toString();
        }

        if (payload.global_search !== null && payload.global_search.length) {
            query.s = payload.global_search.toString();
        }

        RouteHelper.replaceQueryParams(query)
    },

    processRouteQuery(columns) {
        const params = RouteHelper.getAllQueryParams();

        let result = {};

        if (params.f) {
            result.filters = this.createFilterQuery(columns, params.f)
        }

        if (params.s) {
            result.global_search = params.s;
        }

        if (params.p) {
            result.page = params.p;
        }

        if (params.o) {
            result.per_page = params.o;
        }

        return result;
    },

    createFilterQuery(columns, paramFilters) {
        let columnIndex = null,
            filters = [];

        paramFilters.forEach(filter => {
            columnIndex = this.getColumnIndex(columns, filter.name);

            if (columnIndex !== null) {
                filters.push({
                    type: columns[columnIndex].filter.type,
                    name: columns[columnIndex].name,
                    value: filter.value,
                    config: columns[columnIndex].filter
                })
            }
        });

        return filters;
    },

    getColumnIndex(columns, columnName) {
        return findIndex(columns, {
            name: columnName
        });
    },
};

export default RouteHandler;
