<template>
    <div class="form-group has-clear-right">
        <date-range-picker
            v-if="isRangeMode"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
            :timePicker="false"
            :timePicker24Hour="false"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="false"
            v-model="dateRange"
            @update="updateValues"
            @toggle="checkOpen"
            ref="dateRangePicker" class="date-range-picker TEST"
        >
            <template v-slot:input="picker" style="min-width: 350px;">
                {{ picker.startDate | date}} - {{ picker.endDate | date}}
            </template>
        </date-range-picker>
        <vs-button color="primary" type="border" icon="close" @click="clearDateRange"></vs-button>
    </div>

</template>

<script>
    import DateRangePicker from 'vue2-daterange-picker'
    import has from "lodash/has";
    import moment from "moment";

    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

    export default {
        components: { DateRangePicker },
        name: "Date",
        props: {
            column: {
                type: Object,
                default: function () {
                    return {};
                }
            },
            placeholder: {
                type: String,
                default: "Auswählen"
            }
        },
        data() {
            return {
                opens: true,
                dateRange: {
                    startDate: null,
                    endDate: null
                }
            }
        },
        computed: {
            mode() {
                let mode = "single";
                if (has(this.column.filter, "mode") && this.column.filter.mode == "range") {
                    mode = "range";
                }
                return mode;
            },
            isRangeMode() {
                return (this.mode == "range");
            },
        },
        mounted() {
            if(has(this.column, 'filter.init.value')) {
                if(this.isRangeMode) {
                    this.dateRange.startDate = this.column.filter.init.value.start;
                    this.dateRange.endDate = this.column.filter.init.value.end;
                }
            }
        },
        methods: {
            updateValues(data) {
                this.$emit('update-date-range', {
                    column: this.column,
                    start: moment(data.startDate).format('YYYY-MM-DD'),
                    end: moment(data.endDate).add(1, 'day').format('YYYY-MM-DD')
                });
            },
            checkOpen(data) {
                this.checkPopupPosition();
            },
            checkPopupPosition() {
                const parentElement = this.$refs.dateRangePicker.$el;
                setTimeout(() => {

                    const targetElement = parentElement.querySelector('.daterangepicker');
                    const targetRect = targetElement.getBoundingClientRect();

                    const screenWidth = window.innerWidth || document.documentElement.clientWidth;
                    if (targetRect.right > screenWidth) {
                        const offset = targetRect.right - screenWidth;
                        targetElement.style.left = `${parseFloat(getComputedStyle(parentElement).left) - offset}px`;
                    }
                }, 15);
            },
            clearDateRange() {
                this.dateRange = {
                    startDate: null,
                    endDate: null
                };

                this.$emit('update-date-range', {
                    column: this.column,
                    start: null,
                    end: null
                });
            }
        },

        filters: {
            date: function (value) {
                if(value == null) return '';
                var f = function(d) { return d < 10 ? '0' + d : d; };
                return f(value.getDate()) + '.' + f(value.getMonth() + 1) + '.' + value.getFullYear();
            }
        }

    }
</script>

<style lang="scss" scoped>
.popup {
    position: absolute;
    /* Weitere CSS-Stile für dein Popup */
}
</style>
