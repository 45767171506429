const QueryHelper = {
    parseRequestParams(queryParams) {
        let params = {};

        params.limit = queryParams.per_page;
        params.offset = (queryParams.page - 1) * queryParams.per_page;

        if (queryParams.global_search) {
            params.search = queryParams.global_search;
        }

        if (queryParams.filters) {
            params.filter = [];

            for (let i = 0; i < queryParams.filters.length; i++) {
                if(queryParams.filters[i].expression === 'group') {
                    this.parseFilterGroup(params, queryParams.filters[i]);
                } else {
                    this.parseFilter(params, queryParams.filters[i]);
                }
            }
        }


        if(queryParams.sort) {
            if (queryParams.sort.length > 0) {
                params.order = {
                    field: queryParams.sort[0].name,
                    direction: queryParams.sort[0].order
                }

                if (queryParams.sort.length == 2) {
                    params.order1 = {
                        field: queryParams.sort[1].name,
                        direction: queryParams.sort[1].order
                    }
                }
            }
        }

        return params;
    },

    parseFilterGroup(params, queryFilter) {
        const filterGroup = {
            expression: 'group',
            filter: []
        };

        const groupFilter = queryFilter.filter;

        groupFilter.forEach(filter => {
            if(filter.expression === 'group') {
                this.parseFilterGroup(filterGroup, filter);
            } else {
                this.parseFilter(filterGroup, filter);
            }
        });

        params.filter.push(filterGroup);
    },

    parseFilter(params, queryFilter) {
        let filter = {};

        if(queryFilter.type === 'select') {
            if (queryFilter.selected_options.length > 0) {
                const options = queryFilter.selected_options;
                let values = [];

                for (let z = 0; z < options.length; z++) {
                    values.push(options[z]);
                }

                filter.field = queryFilter.name;
                filter.value = values;
                filter.expression = 'exact';

                params.filter.push(filter);
            }
        } else if(queryFilter.type === 'date') {
            if(queryFilter.mode === 'single') {
                filter.field = queryFilter.name;
                filter.value = queryFilter.date;
                filter.expression = 'exact';
                params.filter.push(filter);
            } else {
                filter.field = queryFilter.name;
                filter.value = queryFilter.date;
                filter.expression = 'between';
                params.filter.push(filter);
            }
        } else {

            let filter = {};

            filter.field = queryFilter.name;
            filter.value = queryFilter.text;

            if (queryFilter.expression) {
                filter.expression = queryFilter.expression;
            }

            if (queryFilter.operator) {
                filter.operator = queryFilter.operator;
            }

            params.filter.push(filter)
        }

        return params;
    }
};

export default QueryHelper;
