<template>
    <div>
        <vx-card>
            <vue-bootstrap4-table
                    :rows="data"
                    :columns="columns"
                    :config="config"
                    :actions="actions"
                    @on-change-query="onChangeQuery"
                    :total-rows="total_rows" columnSelection>

                <template slot="row_actions" slot-scope="props">
                    <router-link :to="'/contracts/'+props.row.id" >
                        <vs-button color="warning" type="filled" icon="edit" size="small"
                                   :to="'/contracts/'+props.row.id"></vs-button>
                    </router-link>
                </template>
                <template slot="contract_start" slot-scope="props">
                    <b>
                        {{formatDate(props.cell_value)}}
                    </b>
                </template>
                <template slot="contract_end" slot-scope="props">
                    <b>
                        {{formatDate(props.cell_value)}}
                    </b>
                </template>
                <template slot="status" slot-scope="props">
                    <div class="vbt-table-td">
                        <div class="circle--container">
                            <vx-tooltip text="Vertrag ist ausgelaufen">
                                <span class="mega--circle red" v-if="props.row.status == '3'"></span>
                            </vx-tooltip>
                            <vx-tooltip text="Vertrag gültig">
                                <span class="mega--circle green" v-if="props.row.status == '1'"></span>
                            </vx-tooltip>
                            <vx-tooltip text="Vertrag wurde gekündigt ">
                                <span class="mega--circle yellow" v-if="props.row.status == '2'"></span>
                            </vx-tooltip>
                        </div>
                    </div>
                </template>
                <template slot="empty-results">
                    Keine Verträge gefunden.
                </template>
            </vue-bootstrap4-table>
        </vx-card>
    </div>
</template>

<script>
    import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table'

    import ApiService from "../../../api";

    import moment from 'moment';
    import QueryHelper from "../../../mixins/helper/query.helper";
    import * as qs from "qs";

    export default {
        components: {
            VueBootstrap4Table
        },
        data() {
            return {
                data: [],
                actions: [
                    {
                        btn_text: "Hinzufügen",
                        router_link: '/contracts/add',
                        color: 'success'
                    },
                    {
                        btn_text: "CSV Export",
                        event_name: "on-export-csv",
                    }
                ],
                columns: [
                    {
                        label: "Kunden-Nr.",
                        name: "client.customer_number",
                        filter: {
                            type: "simple",
                            placeholder: "Kunden-Nr. suchen..."
                        },
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: false,
                    },
                    {
                        label: "Kunde",
                        name: "client.company_name",
                        filter: {
                            type: "simple",
                            placeholder: "Firma suchen..."
                        },
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: true,
                    },
                  {
                    label: "Vertragsnummer",
                    name: "contract_number",
                    filter: {
                      type: "simple",
                      placeholder: "VN suchen..."
                    },
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: true,
                  },
                    {
                        label: "Vertragstyp",
                        name: "type.name",
                        filter: {
                            type: "simple",
                            placeholder: "Vertragstyp suchen..."
                        },
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: true,
                    },
                    {
                        label: "Kommentar",
                        name: "internal_comment",
                        filter: {
                            type: "simple",
                            placeholder: "Kommentar suchen..."
                        },
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left'
                    },
                    {
                        label: "Vertragsbeginn",
                        name: "contract_start",
                        slot_name: "contract_start",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: true,
                    },
                    {
                        label: "Vertragsende",
                        name: "contract_end",
                        slot_name: "contract_end",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: true,
                    },
                    {
                        label: "Status",
                        name: "status",
                        slot_name: "status",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: true,
                    },
                  {
                    label: "Vertragsdatei",
                    name: "contract_file",
                    filter: {
                      type: "simple",
                      placeholder: "Vertragsdatei suchen..."
                    },
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: false
                  },
                    {
                        label: "Actions",
                        slot_name: "row_actions",
                        sort: false,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        column_classes: 'actions-column',
                        showCol: true,
                    }],
                config: {
                    checkbox_rows: false,
                    show_refresh_button: false,
                    show_reset_button: false,
                    highlight_row_hover_color: '#f8f8f8',
                    server_mode: true
                },
                queryParams: {
                    sort: [],
                    filters: [],
                    global_search: "",
                    per_page: 10,
                    page: 1,
                },
                total_rows: 0,

            }
        },
        created() {
            this.fetchData()
        },
        computed: {
            requestParams() {
                return QueryHelper.parseRequestParams(this.queryParams);
            }
        },
        methods: {
            fetchData() {
                this.$vs.loading()

                ApiService.get('contracts', {
                    params: this.requestParams,
                    paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
                }).then(response => {
                    this.data = response.data.result;
                    this.total_rows = response.data.total;
                    this.$vs.loading.close()
                })
            },
            formatDate(value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
            },
            onChangeQuery(queryParams) {
                this.queryParams = queryParams;
                this.fetchData();
            },
        },
        mounted() {
        }
    }

</script>
